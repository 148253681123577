import React from "react"
import BackContact from "./backContact"

const RecruitNormal = () => {
  return (
    <section className="recruit">
      <div className="recruit__container">
        <h1 className="recruit__title">採用情報</h1>
        <div className="recruit__desc">
          <p className="recruit__desc_text">現在、求人は行っておりません。</p>
        </div>

        <BackContact link={"/"} linkName={"Topページ"} />
      </div>
    </section>
  )
}

export default RecruitNormal
