import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/heroImage"
import RecruitNormal from "../components/recruitNormal"
//import RecruitDetail from "../components/recruitDetail"

function Recruit({ location }) {
  const data = useStaticQuery(graphql`
    query {
      recruit: file(relativePath: { eq: "recruit.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const description =
    "司法書士法人みつわ合同事務所は大阪市中央区にある司法書士事務所です。大阪メトロ御堂筋線本町駅から徒歩すぐの場所にあるので、通勤にも便利です。私たちと共に成長し、楽しく働ける方を募集しておりますので、ご興味を持たれましたら、是非ともお問い合わせください。"

  return (
    <Layout>
      <SEO
        description={description}
        title="求人情報　採用情報　| 司法書士　大阪"
        pagepath={location.pathname}
        pageimg={data.recruit.childImageSharp.original.src}
        pageimgw={data.recruit.childImageSharp.original.width}
        pageimgh={data.recruit.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"recruit"}
          fluid={data.recruit.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"採用情報"}
        />
        {/*<RecruitDetail />*/}
        <RecruitNormal /> }
      </div>
    </Layout>
  )
}

export default Recruit
